<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- articolo -->
      <template #cell(articolo)="data">
        <span class="font-weight-bolder">
            {{ data.item.descrizione_articolo }}
        </span><br />
        <span class="font-small-2 text-muted">
            codice art.: {{ data.item.codice_articolo }}
        </span>
      </template>

      <!-- prezzo_cadauno -->
      <template #cell(prezzo_cadauno)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.prezzo }} €</span>
        </div>
      </template>

      <!-- subtotale_riga -->
      <template #cell(subtotale_riga)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.subtotale }} €</span>
        </div>
      </template>

      <!-- blocco_iva -->
      <template #cell(blocco_iva)="data">
        {{ data.item.iva+'%' }}
      </template>
      
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'articolo', label: 'Articolo' },
        { key: 'qt', label: 'Quantità' },
        { key: 'um', label: 'UM' },
        { key: 'prezzo_cadauno', label: 'Prezzo cadauno' },
        { key: 'subtotale_riga', label: 'Subtotale' },
        { key: 'blocco_iva', label: 'Iva' },
        { key: 'sconto', label: 'Sconto' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
